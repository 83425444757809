import { createContext, type PropsWithChildren, useContext, useState } from 'react';

interface Blur {
    hasBlur: boolean;
    toggleBlur: (blur: boolean) => void;
}

const initialContext: Blur = {
    hasBlur: false,
    toggleBlur: () => {},
};

const BlurContext = createContext<Blur>(initialContext);

export function BlurContextProvider({ children }: PropsWithChildren<{}>) {
    const [hasBlur, setHasBlur] = useState(initialContext.hasBlur);

    function toggleBlur(blur: boolean) {
        setHasBlur(blur);
    }

    return <BlurContext.Provider value={{ hasBlur, toggleBlur }}>{children}</BlurContext.Provider>;
}

export function useBlur() {
    return useContext(BlurContext);
}
